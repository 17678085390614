import React from 'react';
import classNames from 'classnames';
import {ImageLoadingBehaviorOptions, ImageResizeOptions, WowImage} from 'wix-ui-tpa/cssVars';
import {HoverType} from '../../../../types/galleryTypes';
import s from './ImageUiTpaWrapper.scss';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {classes} from './ImageUiTpaWrapper.st.css';
import {DefaultProductImage} from '@wix/wixstores-client-common-components/dist/es/src/DefaultProductImage/DefaultProductImage';
import {DEFAULT_GALLERY_IMAGE_SIZE, Experiments} from '../../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum ImageUiTpaWrapperDataHook {
  Wrapper = 'ImageUiTpaWrapperDataHook.Wrapper',
  Media = 'ImageUiTpaWrapperDataHook.Media',
  DefaultImage = 'ImageUiTpaWrapperDataHook.DefaultImage',
}

export interface ImageUiTpaWrapperProps extends IGalleryGlobalProps {
  product: any;
  wrapperClassName?: string;
  imageClassName?: string;
  mediaItemIndex?: number;
  resize?: ImageResizeOptions;
  containerAspectRatio: number;
  hoverType: HoverType;
}
export const getWowImageDataHook = (index: number) => `${ImageUiTpaWrapperDataHook.Media}_${index}`;
export const getImageWrapperDataHook = (index: number) => `${ImageUiTpaWrapperDataHook.Wrapper}_${index}`;

export const ImageUiTpaWrapper = ({
  product,
  wrapperClassName,
  mediaItemIndex,
  imageClassName,
  resize,
  containerAspectRatio,
  globals: {isSEO},
}: ImageUiTpaWrapperProps) => {
  const mediaItem = product.media.length > mediaItemIndex && product.media[mediaItemIndex];
  const alt = mediaItem.altText || product.name;
  const isContain = resize === ImageResizeOptions.contain;
  const {experiments} = useExperiments();

  if (mediaItem) {
    return (
      <div
        className={classNames(wrapperClassName, s.imageContainer)}
        data-hook={getImageWrapperDataHook(mediaItemIndex)}>
        <WowImage
          data-hook={getWowImageDataHook(mediaItemIndex)}
          src={mediaItem.url}
          className={classNames(
            {[classes.root]: !experiments.enabled(Experiments.GalleryFixImageBorderRadiusOnHoverZoom)},
            imageClassName
          )}
          stretchImage={!isContain}
          sourceWidth={mediaItem.width}
          sourceHeight={mediaItem.height}
          alt={alt}
          fluid={true}
          loadingBehavior={ImageLoadingBehaviorOptions.none}
          resize={resize}
          shouldUseLQIP={true}
          containerAspectRatio={containerAspectRatio}
          forceImageContain={isContain}
          isSEOBot={isSEO}
        />
      </div>
    );
  } else {
    return (
      <DefaultProductImage
        imageSize={DEFAULT_GALLERY_IMAGE_SIZE}
        dataHook={ImageUiTpaWrapperDataHook.DefaultImage}
        imageContainerDimensions={{height: mediaItem.height, width: mediaItem.width}}
        className={s.defaultImage}
      />
    );
  }
};
